import { Link } from 'gatsby';
import { default as React } from 'react';
import {
  connectStateResults,
  Hits,
  Index,
  PoweredBy,
} from 'react-instantsearch-dom';
import styled from 'styled-components';

const SearchResultStyles = styled.div`
  display: grid;
  margin-bottom: 1em;
  &:hover {
    h4 {
      color: #b28c17;
    }
  }
  .text {
    float: left;
    width: 63%;
    padding: 0.2em;
    margin-bottom: 1em;
    font-size: small;
  }
  img {
    margin-top: -2.5em;
    width: 33%;
    height: 110px;
    vertical-align: middle;
    object-fit: cover;
  }
`;
const HitCountStyles = styled.div`
  padding-bottom: 3em;
  font-size: small;
`;
const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  return hitCount > 0 ? (
    <HitCountStyles className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </HitCountStyles>
  ) : null;
});

const PageHit = ({ hit }) => (
  <SearchResultStyles>
    <Link to={hit.path}>
      <h4>{hit.context?.article?.title}</h4>
      <div className="text">{hit.context?.article?.excerpt}</div>
      <div className="text">Published {hit.context?.article?.date}</div>
      <img src={hit.context?.article?.hero?.narrow?.src}></img>
    </Link>
  </SearchResultStyles>
);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
);

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map((index) => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <PoweredBy />
  </div>
);

export default SearchResult;
