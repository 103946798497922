import styled, { css } from 'styled-components';
import SearchBox from './search-box';

const open = css`
  width: 10em;
  background: ${({ theme }) => theme.background};
  cursor: text;
`;

const closed = css`
  width: 0;
  background: transparent;
  cursor: pointer;
  margin-left: -1em;
  padding-left: 1em;
`;

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .SearchInput {
    outline: none;
    border: ${({ hasFocus }) => (hasFocus ? 'auto' : 'none')};
    font-size: 1em;
    transition: 100ms;
    border-radius: 12px;
    padding: 0.5em;
    justify-content: space-between;
    color: ${({ theme }) => theme.primary};
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    ${({ hasFocus }) => (hasFocus ? open : closed)}
  }

  .SearchIcon {
    width: 1em;
    display: ${({ hasFocus }) => (hasFocus ? 'none' : 'flex')};
    @media (max-width: 700px) {
    }
    @media screen and (max-width: 375px) {
      font-size: x-large;
    }
    @media screen and (max-width: 414px) {
      font-size: x-large;
    }
    font-size: ${({ hasFocus }) => (hasFocus ? 'large' : 'x-large')};
    color: #83979f;
    pointer-events: none;
    justify-content: space-between;
  }
`;
