import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import Headroom from 'react-headroom';
import { useColorMode } from 'theme-ui';
import ArticlesContextProvider from '../../sections/articles/Articles.List.Context';
import { globalStyles } from '../../styles/global';
import NavigationFooter from '../Navigation/Navigation.Footer';
import NavigationHeader from '../Navigation/Navigation.Header';

/**
 * <Layout /> needs to wrap every page as it provides styles, navigation,
 * and the main structure of each page. Within Layout we have the <Container />
 * which hides a lot of the mess we need to create our Desktop and Mobile experiences.
 */
const Layout: React.FC<{}> = ({ children }) => {
  const [colorMode] = useColorMode();

  useEffect(() => {
    parent.postMessage({ theme: colorMode }, '*');
  }, [colorMode]);

  return (
    <ArticlesContextProvider>
      <Container>
        <Global styles={globalStyles} />
        <Headroom
          style={{
            zIndex: '11',
            WebkitTransition: 'all .5s ease-in-out',
            MozTransition: 'all .5s ease-in-out',
            OTransition: 'all .5s ease-in-out',
            transition: 'all .5s ease-in-out',
          }}
        >
          <H1 style={{ paddingBottom: '15px' }}>
            <NavigationHeader />
          </H1>
        </Headroom>
        {children}
        <NavigationFooter />
      </Container>
    </ArticlesContextProvider>
  );
};

export default Layout;

const Container = styled.div`
  position: relative;
  background: ${(p) => p.theme.colors.background};
  transition: ${(p) => p.theme.colorModeTransition};
  min-height: 100vh;
`;

const H1 = styled.div`
  background: ${(p) => p.theme.colors.background};
`;
