import styled, { css } from 'styled-components';
import SearchResult from './search-result';

const Popover = css`
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  margin-top: 0.5em;
  width: 80vw;
  max-width: 30em;
  box-shadow: 0 0 5px 0;
  padding: 1em;
  border-radius: 12px;
  background: ${({ theme }) => theme.background};
`;

export default styled(SearchResult)`
  display: ${(props) => (props.show ? `block` : `none`)};
  ${Popover}
  @media (max-width: 700px) {
    width: 35em;
  }

  .HitCount {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1.5rem;
  }
  .text {
    @media (max-width: 700px) {
      font-size: 12px;
    }
  }

  .Hits {
    h4 {
      font-size: medium;
      padding-bottom: 1em;
    }
    ul {
      list-style: none;
      margin-left: 0;
    }

    li.ais-Hits-item {
      margin-bottom: 1.5em;
      height: fit-content;
      border-bottom: 1px solid black;

      a {
        color: ${({ theme }) => theme.foreground};

        h4 {
          margin-bottom: 0.2em;
        }
      }
    }
  }

  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;

    svg {
      width: 70px;
    }
  }
`;
